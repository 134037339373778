
@import 'assets/scss/helpers/functions';

.home-page__wrapper {
    margin-bottom: rem(72);
}

.home-page__steps {
    margin-bottom: rem(51);
}

.home-page__glow-title {
    margin-bottom: rem(44);
}

.home-page__title {
    margin-bottom: rem(44);
    font-size: rem(29);
    font-weight: bold;
    line-height: rem(29);
    font-family: 'Gotham Ultra';
}

.home-page__services-list {
    margin-bottom: rem(72);
}
